<template>
  <div class="league"
       style="padding-bottom: 20px">
    <!-- <van-nav-bar
      title="五大联赛"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->
    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/league_banner.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img"><img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>活动对象:</p>
              <span style="color:var(--li-colortext);">贝宝体育全站会员</span>
            </div>
            <div class="title-item">
              <p>活动平台:</p>
              <span style="color:var(--li-colortext);">所有体育游戏场馆</span>
            </div>
            <div class="title-item">
              <p>活动时间:</p>
              <span style="color:var(--li-colortext);">{{datalist.start_time}} ~ {{datalist.end_time}}</span>
            </div>
            <div class="title-item">
              <p>活动要求:</p>
              <span style="color:var(--li-colortext);">完善资料</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead style="
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                  ">
              <tr style="
                  background: linear-gradient(90deg, #4581fa, #63b3fc);
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                ">
                <th style="
                        color: #fff;
                        border-right: 1px solid #e2d2bf;
                        font-family: PingFang SC;
                        font-weight: 400;
                        border-top-left-radius: 10px;
                      ">
                  当前有效流水
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  当前输赢
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ datalist.today_beat }}</td>
                <td>{{ datalist.today_sy }}</td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead style="
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                  ">
              <tr style="
                  background: linear-gradient(90deg, #4581fa, #63b3fc);
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                ">
                <th style="
                        color: #fff;
                        border-right: 1px solid #e2d2bf;
                        font-family: PingFang SC;
                        font-weight: 400;
                        border-top-left-radius: 10px;
                      ">
                  昨日有效流水
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  昨日输赢
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ datalist.today_beat2 }}</td>
                <td>{{ datalist.today_sy2 }}</td>
              </tr>
            </tbody>
          </table>
          

          <table>
            <thead style="
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                  ">
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);border-top-left-radius: 10px;border-top-right-radius: 10px;
                ">
                <th style="
                        color: #fff;
                        border-right: 1px solid #e2d2bf;
                        font-family: PingFang SC;
                        font-weight: 400;
                        border-top-left-radius: 10px;
                      ">
                  昨日总投注额
                </th>
                <th style="
                        color: #fff;
                        border-right: 1px solid #e2d2bf;
                        font-family: PingFang SC;
                        font-weight: 400;
                      ">
                  包赔比例
                </th>
                <th style="
                        color: #fff;
                        border-right: 1px solid #e2d2bf;
                        font-family: PingFang SC;
                        font-weight: 400;
                      ">
                  包赔金上限
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  流水倍数
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in datalist.config"
                  :key="index">
                <td>≥{{ item.beat }}</td>
                <td>{{ item.day_bouns }}%</td>
                <td>{{ item.week_bonus }}</td>
                <td v-if="index == 0" :rowspan="datalist.config.length">{{ datalist.limit }}</td>
              </tr>
            </tbody>
          </table>


          <div class="Claimable">
            <div class="Claimable-box">
              <div class="Claimable-left">
                <img src="../assets/img/pcbb/money-w.png">
              </div>
              <div class="Claimable-right">
                <p style="color:var(--li-colortext);">可领包赔金</p>
                <p>{{datalist.now_sc_money}}</p>  
              </div>
            </div>

            <div class="Claimable-box">
              <div class="Claimable-left">
                <img src="../assets/img/pcbb/bank-w.png">
              </div>
              <div class="Claimable-right">
                <p style="color:var(--li-colortext);">流水限制</p>
                <p>{{datalist.now_sc_limit}}</p>
              </div>
            </div>
          </div>

          <div class="btn-box">
            <div @click="applyWay">立即领取</div>
          </div>
        </div>

        <!-- 申请记录 -->
        <div class="rule-content"
             style="margin-bottom: 30px;">
          <div class="rule-content-title4"
               style="margin-top: 25px;"></div>
               <p style="font-size: 14px;text-align: center;margin-top: 15px;color: var(--td-colortext);">仅展示近10笔，最长保留30天</p>
          
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc);">
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;border-top-left-radius: 10px;">
                  时间
                </th>
                <th style="color: #fff;border-right: 1px solid #e2d2bf;font-family: PingFang SC;font-weight: 400;">
                  红利
                </th>
                <th style="color: #fff; border-top-right-radius: 10px">
                  状态
                </th>
              </tr>
            </thead>
            <tbody v-if="datalist.applyed_data">
              <tr v-for="(item, index) in datalist.applyed_data"
                  :key="index">
                <td>{{ item.created_at }}</td>
                <td>{{ item.bouns_amout }}</td>
                <td>{{ item.status == 0 ? '审核中' : item.remark }}</td>
              </tr>
            </tbody>
          </table>


        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1</span>
                所有贝宝体育会员实名制后均可参与本活动。
              </li>
              <li style="color: red;">
                <span>2</span>
                由于体育场馆结算有廷时的情况出现，因此当天的彩金是需要次日才可以领取，当天领取的是按昨天的投注数据和负盈利来计算。切记！
              </li>
              <li v-if="datalist.config">
                <span>3</span>
                本活动每天只可申请一次，仅限于体育场馆下注指定赛事，不同场馆的注单会相加统计。举例：在A体育场馆有效投注10000元，赢5000元。在B体育场馆有效投注10000元，输6000元。则总有效投注为20000元，负盈利为1000元，则可获得1000*{{datalist.config[2].day_bouns}}%={{datalist.config[2].week_bonus}}元彩金（{{datalist.limit}}倍流水可提款）。
              </li>
              <li>
                <span>4</span>
                以上负盈利，仅对已结算并产生输赢结果的注单进行计算，特殊投注、取消的赛事将不计算在内。负盈利计算仅限独赢、让球、大小、单双四个盘口的全场与半场。任何注单提前兑现以及在同一赛事中同时投注对冲盘口，将不计算在内。
              </li>
              <li>
                <span>5</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>6</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。若发现有套利客户，对冲，或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>7</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>


    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-picker title="请选择场馆"
                  show-toolbar
                  value-key="name"
                  :columns="datalist.youxi"
                  @confirm="confirm"
                  @cancel="cancel" />
    </van-popup>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'
export default {
  data() {
    return {
      datalist: [],
      isshow: false,
      timer: null,
      is_token: false,
      cueTheme: '',
      showPicker:false

    }
  },
  mounted() {
    this.cueTheme = window.localStorage.getItem('cueTheme')

    if (localStorage.getItem('access_token')) {
      this.is_token = true
    } else {
      this.is_token = false
    }
    this.getdata()
  },
  methods: {
    confirm(e) {
      let _this = this
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'Single',
                  gameid:e.id
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          // 领取
          uni.postMessage({
            data: {
              urlApply: 'vipActapplyobject',
              actobject: {
                act: 'Single',
                gameid:e.id
              },
            }
          })
        }
      })
      this.showPicker = false
    },
    cancel() {
      this.showPicker = false
    },
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true'
            }
          })
        })
      }
    },

    getdata() {
      var _this = this
      var data = '?type=Single'
      getdata(data).then((res) => {
        /*  console.log(res); */
        this.datalist = res.message
      })
    },
    changeisshow() {
      clearInterval(this.timer)
      this.isshow = true

      this.timer = setTimeout(() => {
        this.isshow = false
      }, 3000)
    },
    applyWay() {
      this.showPicker = true
    }
  }
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f7f8fa;
}

.liveLottery {
  .activityRules {
    position: relative;
    background-color: #fff;
    margin: 10px 15px;
    // padding-bottom: 1px;
    border-radius: 8px;

    .title {
      width: 100%;
      padding-top: 20px;
      text-align: center;
      color: #b2987f;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;
      padding-bottom: 5px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 70px;
        font-size: 12px;

        > span {
          text-align: center;
          position: absolute;
          top: 0px;
          left: 0;
          font-family: PingFang SC;
          font-weight: 400;
          color: #c8a57e;
        }
      }
    }

    table {
      width: calc(100% - 30px);
      margin: 0 15px;
      text-align: center;
      border-collapse: separate;
      border-spacing: 0;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-family: PingFang SC;
      border-bottom: 2px solid #e2d2bf;
      font-weight: 400;
      color: #684728;

      th,
      td {
        height: 40px;
        padding: 10px 0;
        color: #684728;
        font-size: 13px;
        text-align: center;
      }
    }

    tr:nth-child(1) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    tr:nth-child(odd) {
      background-color: #f7f6f3;
    }

    td {
      border-right: 2px solid #e2d2bf;
    }

    td:nth-child(1) {
      border-left: 2px solid #e2d2bf;
    }
  }

  .Rules {
    margin: 10px 15px;
    // margin: 0 20px;
    // margin-top: 15px;
    font-family: PingFang SC;
    border-radius: 8px;
    background-color: #fff;

    .title {
      padding-top: 20px;
      width: 100%;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c8a57e;
      position: relative;
    }

    .activity_rules {
      padding: 20px 10px;

      ul li {
        color: #000;
        margin-bottom: 16px;
        position: relative;
        padding-left: 18px;
        font-size: 13px;
        line-height: 1.5em;

        > span {
          width: 16px;
          height: 16px;
          text-align: center;
          line-height: 16px;
          border-radius: 50%;
          position: absolute;
          top: 0px;
          left: 0;
          font-size: 12px;
        }
      }
    }
  }
}

.btn-box {
  padding-bottom: 5px;

  > div {
    font-family: PingFang SC;
    font-weight: 400;

    &:nth-child(1) {
      height: 40px;
      color: #fff;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      background: linear-gradient(0deg, #2a6afd, #63c6ff);
      margin: 25px 0px 15px 0px;
    }

    &:nth-child(2) {
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #2a6afd;
      color: #418beb;
      margin: 15px 0px 15px 0px;
    }
  }
}

.Claimable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .Claimable-box {
    width: 49%;
    display: flex;
    align-items: center;
    border: 2px solid #d8dbde;
    border-radius: 8px;
    margin-top: 20px;
    overflow: hidden;
    font-size: 14px;
    &:nth-child(1){
      .Claimable-left{
        img{
          height: 30px;
        }
      }
    }
    &:nth-child(2){
      .Claimable-left{
        img{
          height: 24px;
        }
      }
    }
    .Claimable-left {
      border-right: 2px solid #d8dbde;
      padding: 10px;
      width: 45%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(90deg, #2a6afd, #63c6ff);
    }
    .Claimable-right {
      width: 55%;
      color: #516283;
      p {
        display: flex;
        align-items: center;
        height: 35px;
        justify-content: center;
        &:nth-child(1) {
          border-bottom: 2px solid #d8dbde;
        }
        &:nth-child(2) {
          color: #2a6afd;
        }
      }
    }
  }
}
</style>
